.react-datepicker {
  font-family: "Marianne", sans-serif !important;
  border-color: #e1e5f3;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #f9fafc;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #d5dbef;
}

.react-datepicker__month .react-datepicker__month-text {
  text-transform: capitalize;
  color: #1e1e1e;
  padding: 4px 8px;
  margin: 4px !important;
  width: 96px !important;
  font-weight: 400;
}

.react-datepicker__month .react-datepicker__month--selected {
  border-radius: 0.3rem;
  background-color: #000091;
  color: #fff;
}

.react-datepicker__month .react-datepicker__month--disabled {
  color: #e7e7e7;
  border-radius: 0.3rem;
}

.react-datepicker__header {
  text-align: center !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom-color: #e7e7e7 !important;
}

.react-datepicker__navigation--previous {
  border-right-color: white;
}

.react-datepicker__navigation--next {
  border-left-color: white;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
